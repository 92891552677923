@import "~dw-uk-bootstrap/scss/_functions.scss";
@import "~dw-uk-bootstrap/scss/_mixins.scss";
@import "~dw-uk-bootstrap/scss/themes/_hsbc.scss";

$header-primary: $primary;
$header-secondary: #911440;
$light-button-border: 2px solid lighten(desaturate(adjust-hue($header-primary, -3), 23.87), 29.22);
$quicklinks: $header-secondary;
$recommended-background: #3c3c3c;
$recommended-cta: rgba(137,34,63,1);
$recommended-cta-hover: rgba(137,34,63,0.7);
$footer-background: #3c3c3c;
$footer-color: #ffffff;
$footer-phone: light;
$social-links-background: #606060;
$social-icon-color: #bfbfbf;
$legal-background: #4f4f4f;
$empty-cell-grey: #ccc;

@import "./base.scss";
@import './components/gift-cards/gift-cards.scss';
@import './components/gift-cards/e-vouchers/e-vouchers.scss';
@import './components/**/*.hsbc.scss';
