.hsb {
    #top-links{
        margin-top: 45px;
        @include media-breakpoint-down(sm) {
            margin-top: -10px;
        }
        text-align: left;
        @media (min-width:768px) and (max-width:991px) {
            display: block !important;
        }
    }
    .hsb-right-panel {
        margin: 45px 0 0;
        @media (min-width:768px) and (max-width:1023px) {
            margin: -20px 0 0;
            .checkout-link{
                padding: 8px;
            }
        }
        @include media-breakpoint-down(sm) {
            margin: -5px 45px 0;
        }
    }
    header#new-homepage-test-header{
        .masthead{
            #top-links {
                #account{
                    float: left;
                }
                .help-link{
                    float: left;
                }
            }
            .checkout-link:hover{
                background: #93000B;
            }
            .hsbc-right-panel-sticky{
                display: none !important;
            }
            #cart{
                padding: 5px 5px 15px;
                span.total{
                    line-height: 40px;
                }
            }
        }
    }
    .hidden-hsb {
        display: none!important;
    }
}

.hsb header#new-homepage-test-header .masthead #top-links #account, header#new-homepage-test-header .masthead #top-links .help-link{
    float: left;
}
header#new-homepage-test-header.is-sticky .row.hsbc-left-panel-sticky{
    display: none !important;
}
@include media-breakpoint-down(sm){
    .hsb header#new-homepage-test-header .masthead svg path{
        fill: #333 !important;
    }
    .hsb header#new-homepage-test-header #main-navigation nav{
        margin-top: 0;
    }
}